var Prolook = {};

Prolook.events = {

	init: function() {

		Prolook.events.bindEvents();

	},

	bindEvents: function() {
        
        $(".cobrowse").click(Prolook.cobrowse.createCode);

        $(".uk-button").click(Prolook.cobrowse.closeModal);

	},

}
Prolook.cobrowse = {

    closeModal: function(){

            setTimeout(function() {

                $('#cobrowse-code').hide()
                
                $('.image-loading').show()
                
            }, 500); 
        
    },

	createCode: function() {

        let self = this;

        CobrowseIO.client().then(function() {

            // create a code a display it to the user
            CobrowseIO.createSessionCode().then( code => {

                self.cobrowseID = code;   
            
                  setTimeout(function() {

                    var coCode = $('#cobrowse-code').text(code)

                    $('.image-loading').hide()
                     
                    coCode.show()

                }, 2000); 
            
            });

        }); 

    }

}
// check if the live chat not exist
if ($(".ContactBubble ContactBubble--onLeft").length == [0]){

    $('.float-btn-container').css('bottom','40px')

} 

$(document).ready(Prolook.events.init);

